
@import "../../bootstrap/variables.less";

@body-bg:					#fff;

@text-color:				#000;
@link-color:				#C60000;
@link-hover-color:			#C60000;
@link-hover-decoration:		underline;

@font-family-base:			Verdana, Arial, Helvetica, sans-serif;
@font-size-base:			10px;
@line-height-base:			1;

@font-size-h1:				50px;
@headings-small-color:		#fff;

@thumb-width:				490px;
@thumb-height:				368px;
@thumb-margin-top:			2px;
@thumb-margin-right:		2px;
@thumb-margin-bottom:		2px;
@thumb-margin-left:			2px;
@thumb-border-width:		1px;
@thumb-border:				1px solid #000;
@thumb-hover-border:		1px solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight: 		bold;

@pager-border:					none;
@pager-padding:					2px;
@pager-text-decoration:			underline;
@pager-color:					#C60000;
@pager-color-active:			#C60000;
@pager-text-decoration-active:	none;
@pager-hover-bg:				transparent;
@pager-font-weight-active:		normal;
@pager-font-size:				24px;
@pager-font-size-mobile:		24px;

@social-links-margin:		20px 0;

@sites-list-font-size:				18px;
@sites-list-color:					#f09;
@sites-list-title-margin-bottom:	2px;
@sites-list-title-size:				24px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		underline;

@sites-footer-traffictrade-size:	18px;
@sites-footer-traffictrade-margin:	20px 0;
@sites-footer-margin:				2px;
@sites-footer-size:					12px;
@sites-footer-color:				#C60000;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/pagination.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/sites_footer.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

a {
	cursor: pointer;
	text-decoration: underline;
}
h1 {
	text-align: center;
	margin: 0 auto;
	font-size: 24px;
	padding: 2px;
	.title {
		display: inline-block;
		color: #FF0099;
	}
	div {
		display: inline-block;
		margin: 2px 5px;
	}
}
.links a {
	display: inline-block;
	font-size: 18px;
	&.nolink {
		text-decoration: none;
		color: #000;
	}
}
#language-switcher {
	position: absolute;
	right: 10px;
	top: 0;
}
.pagination {
	& a.active {
		text-decoration: none;
		cursor: none;
		color: #000;
	}
}
.outro {
	font-size: 24px;
	margin: 18px 0;
}
